<template lang="pug">
div
  vue-skeleton-loader.loader(
    type="rect"
    width="50%"
    height="24px"
    :rounded="true"
    v-if="isLoading"
  )
  span(v-else-if="isError")
    UilExclamationCircle(color="#be1820")
  template(v-else)
    div(
      v-if="roundedValue < 1000000 || typeof roundedValue === 'string'"
      :class="upliftVariantClass(roundedValue, columnKey)"
    )
      .secondary-currency(v-if="secondary && !isUplift") {{ `+${formatNumber({ value: roundedValue, currency, key: columnKey, usedOnAnalyticsPage: true })}` }}
      .secondary-currency(v-else-if="secondary && isUplift") {{ `${formatNumber({ value: roundedValue, currency, key: columnKey, usedOnAnalyticsPage: true })}` }}
      .2-col(v-else) {{ formatNumber({ value: roundedValue, currency, key: columnKey, usedOnAnalyticsPage: true }) }}
    om-tooltip(transition="fade" v-else :class="upliftVariantClass(roundedValue, columnKey)")
      span {{ formatNumber({ value: roundedValue, currency, isLongFormat: true, key: columnKey, usedOnAnalyticsPage: true }) }}
      template(slot="trigger")
        .secondary-currency(v-if="secondary && !isUplift") {{ `+${formatNumber({ value: roundedValue, currency, key: columnKey, usedOnAnalyticsPage: true })}` }}
        .secondary-currency(v-else-if="secondary && isUplift") {{ `${formatNumber({ value: roundedValue, currency, key: columnKey, usedOnAnalyticsPage: true })}` }}
        div(v-else) {{ formatNumber({ value: roundedValue, currency, key: columnKey, usedOnAnalyticsPage: true }) }}
</template>

<script>
  import campaignReports from '@/mixins/campaignReports';
  import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
  import { UilExclamationCircle } from '@iconscout/vue-unicons';

  export default {
    components: { SkeletonLoader, UilExclamationCircle },
    mixins: [campaignReports],
    props: {
      value: { type: [Number, String] },
      columnKey: { type: String, required: true },
      currency: { type: String, required: false },
      secondary: { type: Boolean, default: false },
      isLoading: { type: Boolean, default: false },
      isError: { type: Boolean, default: false },
    },
    computed: {
      roundedValue() {
        if (this.columnKey === 'assistedRevenue') {
          return Math.round(this.value);
        }
        return this.value;
      },
      isUplift() {
        return ['uplift', 'revenueUplift'].includes(this.columnKey);
      },
    },
    methods: {
      upliftVariantClass(value, column) {
        if (column !== 'uplift' && column !== 'revenueUplift') return '';
        if (value === this.$t('analyticsPage.baseline')) return 'baseline';
        if (value > 0) return 'positive';
        if (value < 0) return 'negative';
        return '';
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '../sass/variables/_colors'

  .total-analytics-total
    font-size: 28px
    font-weight: 700
    line-height: 1
    margin-top: 6px
    align-self: flex-start
    width: 100%

    .ds-tooltip
      justify-content: flex-start

    .secondary-currency
      color: $om-gray-600
      font-size: 1.25rem
      font-weight: 400

  @media screen and (max-width: 1599px)
    .total-analytics-total
      .secondary-currency
        font-size: 18px

  .brand-table-td-variant
    .secondary-currency
      color: $om-gray-600
      font-size: 0.75rem
      font-weight: 400
      width: 100%

  .table-row-value
    .secondary-currency
      color: $om-gray-600
      font-size: 0.75rem
      font-weight: 400

    &-highlighted
      color: $om-gray-800
      font-weight: 500
      font-size: 20px
      padding: 10px 0
      min-height: 76px
      display: flex
      flex-direction: column
      align-items: flex-end
      justify-content: center

      .secondary-currency
        color: $om-gray-600
        font-size: 1rem
        font-weight: 400
        min-height: 0
        padding: 0

  .baseline
    color: #8F97A4

  .positive
    color: $om-alert-green-600
    font-weight: 700

    .secondary-currency
      color: $om-alert-green-600

  .negative
    color: $om-alert-red-500

    .secondary-currency
      color: $om-alert-red-500
  .loader
    margin-left: auto
</style>
