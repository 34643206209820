import formatStat from '@/mixins/formatStat';

export default {
  data() {
    return {
      simpleValues: [
        'impressions',
        'visitors',
        'conversions',
        'conversionRate',
        'visitorsWithOrders',
        'totalOrdersHeader',
        'chanceToWin',
        'uplift',
        'goalConversion',
      ],
    };
  },
  mixins: [formatStat],
  methods: {
    sumOrderNumber(orders) {
      if (orders === null) return 0;
      return Object.values(orders).reduce((orderSum, value) => {
        orderSum += value;
        return orderSum;
      }, 0);
    },
    isNullValue(value) {
      return value === null || value === 0;
    },
    getAdditionalCurrencies(value) {
      if (!value) return {};
      let res = Object.entries(value).reduce((notNullValues, [currencyName, currencyValue]) => {
        if (currencyValue !== 0 && currencyValue !== 'N/A' && currencyValue !== null) {
          notNullValues[currencyName] = currencyValue;
        }
        return notNullValues;
      }, {});

      if (Object.keys(res).length === 0) {
        res = {
          '': 0,
        };
      }

      return res;
    },
  },
};
